<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-row>
          <v-col cols="4">
            <v-card color="blue lighten-1">
              <v-card-text> المدفوعات </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card color="orange lighten-1">
              <v-card-text> المديونات </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card color="red lighten-1">
              <v-card-text> المتبقي </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "employee",
};
</script>
